import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Seo from "../components/seo";

import Half8Solid from "../icons/half-8-solid.svg";
import Half2Solid from "../icons/half-2-solid.svg";

const BlogDetail = ({ data }) => {
  const { post } = data;

  return (
    <Layout>
      <Seo title={post.title} description={post.description} image={post.image.url} form={true} />

      <div className="page-detail">
        <div className="hero">
          <div className="container-lg position-relative">
            <Half2Solid className="ico-svg ico-half-2-solid ico-right" />
            <Half8Solid className="ico-svg ico-half-8-solid ico-left" />
            <div className="row">
              <div className="col-10 col-md-8">
                <h1 className="hero__title">{post.title}</h1>
                {post.date && <div className="page-detail__date">{post.date}</div>}
              </div>
            </div>
          </div>
          <div className="hero__text-scroll hero__text-scroll--classic">
            <div className="text-scroll" aria-hidden="true">
              <div className="text-scroll__wrap">
                <span className="text-uppercase blog">Blog</span>
              </div>
              <div className="text-scroll__wrap text-scroll__wrap--repeat">
                <span className="text-uppercase blog">Blog</span>
              </div>
            </div>
          </div>
        </div>

        <div className="page-detail__intro">
          <div className="container-lg">
            <div className="row">
              <div className="col-12">
                <div className="d-flex flex-column flex-md-row align-items-md-end position-relative">
                  {/* {post.category && <div className="page-detail__category">{post.category}</div>} */}
                  <div
                    dangerouslySetInnerHTML={{ __html: post.abstract?.childMarkdownRemark?.html }}
                    className="page-detail__intro-text"
                  ></div>
                  <div className="page-detail__img-wrap">
                    <GatsbyImage
                      image={getImage(post.image)}
                      alt={post.image.description}
                      title={post.image.title}
                      objectPosition="center center"
                      className="page-detail__img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="page-detail__text">
          <div className="container-lg pb-4">
            <div className="row">
              <div className="col-12 col-md-10 offset-md-1  col-lg-8 offset-lg-2">
                <div
                  dangerouslySetInnerHTML={{ __html: post.text?.childMarkdownRemark?.html }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BlogDetail;

export const query = graphql`
  query ($contentful_id: String) {
    post: contentfulPost(contentful_id: { eq: $contentful_id }) {
      title
      description
      date(formatString: "DD MMMM YYYY", locale: "it")
      abstract {
        childMarkdownRemark {
          html
        }
      }
      category
      text {
        childMarkdownRemark {
          html
        }
      }
      link
      image {
        title
        description
        url
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`;
